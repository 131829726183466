import React from 'react';
import { useQuery } from '@tanstack/react-query';
import TokenTable from './TokenTable';
import UniswapPositions from './UniswapPositions';

const API_URL = process.env.REACT_APP_API_URL;

// Function to format large numbers
const formatValue = (value) => {
  if (value >= 1000000000) {
    return `$${(value / 1000000000).toFixed(2)}B`;
  } else if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(2)}M`;
  } else if (value >= 1000) {
    return `$${(value / 1000).toFixed(2)}K`;
  } else {
    return `$${value.toFixed(2)}`;
  }
};

async function fetchTreasury() {
  const response = await fetch(`${API_URL}/treasury`);
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
}

async function fetchLiquidity() {
  const response = await fetch(`${API_URL}/liquidity`);
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
}

const ValueCard = ({ title, value, subtitle, trend }) => (
  <div className="flex flex-col items-center text-center">
    <div className="mb-1">
      <p className="text-sm text-gray-500 dark:text-gray-400">
        {title}
      </p>
      <div className="flex items-center justify-center space-x-2">
        <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
          {formatValue(value)}
        </p>
        {trend && (
          <span className={`text-sm ${trend > 0 ? 'text-green-500' : 'text-red-500'}`}>
            {trend > 0 ? '+' : ''}{trend}%
          </span>
        )}
      </div>
      {subtitle && (
        <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
          {subtitle}
        </p>
      )}
    </div>
  </div>
);

const AddressSummary = ({ address }) => {
  const {
    data: tokensData,
    isLoading: tokensLoading,
    isError: tokensError,
    error: tokensFetchError,
    refetch: refetchTokens,
  } = useQuery({
    queryKey: ['treasury'],
    queryFn: fetchTreasury,
    staleTime: 5 * 60 * 1000,
    refetchInterval: 20 * 60 * 1000,
  });

  const {
    data: liquidityData,
    isLoading: liquidityLoading,
    isError: liquidityError,
    error: liquidityFetchError,
    refetch: refetchLiquidity,
  } = useQuery({
    queryKey: ['liquidity'],
    queryFn: fetchLiquidity,
    staleTime: 5 * 60 * 1000,
    refetchInterval: 20 * 60 * 1000,
  });

  if (tokensLoading || liquidityLoading) {
    return (
      <div className="text-center text-gray-500 py-4">Loading treasury data...</div>
    );
  }

  if (tokensError || liquidityError) {
    return (
      <div className="text-center text-red-500 py-4">
        Error loading data: {tokensFetchError?.message || liquidityFetchError?.message}
        <button
          onClick={() => {
            refetchTokens();
            refetchLiquidity();
          }}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  const treasuryTokenValue = tokensData.reduce((acc, token) => acc + token.value, 0);
  const liquidityValue = liquidityData.reduce((acc, pool) => acc + pool.value, 0);
  const totalTreasuryValue = treasuryTokenValue + liquidityValue;

  return (
    <div className="w-full mt-8 px-4 py-6 md:px-8">
      <div className="max-w-6xl mx-auto">
        {/* Total Value Card */}
        <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
          <div className="p-6">
            <div className="flex justify-center gap-12">
              <ValueCard
                title="Total Treasury Value"
                value={totalTreasuryValue}
              />
            </div>
          </div>
        </div>

        {/* Token and Liquidity Values */}
        <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
          <div className="p-6">
            <div className="flex justify-center gap-12">
              <ValueCard
                title="Tokens"
                value={treasuryTokenValue}
              />
              <ValueCard
                title="Liquidity"
                value={liquidityValue}
              />
            </div>
          </div>
        </div>

        {/* Tokens Table */}
        <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 pb-8">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4 text-center">
            Tokens
          </h3>
          <TokenTable tokens={tokensData} />
        </div>

        {/* Uniswap Positions */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 pb-8">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4 text-center">
            Uniswap Positions
          </h3>
          <UniswapPositions liquidity={liquidityData} />
        </div>
      </div>
    </div>
  );
};

export default AddressSummary;