import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { DollarSign, BarChart2 } from 'lucide-react';
import SupplyBreakdownChart from './TokenBlock/SupplyBreakdownChart';

const API_URL = process.env.REACT_APP_API_URL;

const formatMarketCap = (marketCap) => {
  if (marketCap >= 1000000000) {
    return `$${(marketCap / 1000000000).toFixed(2)}B`; // Billions
  } else if (marketCap >= 1000000) {
    return `$${(marketCap / 1000000).toFixed(2)}M`; // Millions
  } else if (marketCap >= 1000) {
    return `$${(marketCap / 1000).toFixed(2)}K`; // Thousands
  } else {
    return `$${marketCap}`;
  }
};

async function fetchTokenInfo() {
  const response = await fetch(`${API_URL}/token`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
}

const Home = () => {
  const {
    data,
    isLoading,
    isError,
    error
  } = useQuery({
    queryKey: ['token'],
    queryFn: fetchTokenInfo,
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000
  });

  const getMetrics = (data) => [
    {
      label: 'Price',
      value: `$${Number(data?.price).toFixed(2)}` || '$0.00',
      icon: DollarSign
    },
    {
      label: 'Market Cap',
      value: formatMarketCap(data?.market_cap) || '$0.00',
      icon: BarChart2
    }
  ];

  return (
    <div className="w-full mt-8 px-4 py-6 md:px-8">
      <div className="max-w-6xl mx-auto">
        {/* Header with Metrics */}
        <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl">
          <div className="p-6">
            {isLoading ? (
              <div className="text-center text-gray-500 py-8">Loading token info...</div>
            ) : isError ? (
              <div className="text-center text-red-500 py-8">Error loading token info: {error.message}</div>
            ) : (
              <div className="flex justify-center gap-12">
                {getMetrics(data).map((metric) => (
                  <div
                    key={metric.label}
                    className="flex flex-col items-center text-center"
                  >
                    <div className="mb-2">
                      <div className="p-3 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                        <metric.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                        {metric.label}
                      </p>
                      <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                        {metric.value}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* Supply Breakdown Chart */}
        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 pb-8 overflow-hidden">
          <div className="w-full h-[400px]">
            <SupplyBreakdownChart supply={data?.supply} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;