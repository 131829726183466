import React, { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { addressLabels } from "../addressLabels";

const API_URL = process.env.REACT_APP_API_URL;

const HoldersTable = () => {
  const [filterMode, setFilterMode] = useState("All");

  const { data: holders, isLoading, isError, error } = useQuery({
    queryKey: ["holders"],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/holders`);
      if (!response.ok) throw new Error("Failed to fetch holders data");
      const data = await response.json();
      return data.holders;
    },
    staleTime: 5 * 60 * 1000,
    refetchInterval: 20 * 60 * 1000,
  });

  const filteredHolders = useMemo(() => {
    if (!holders) return [];
    if (filterMode === "Ignore Protocol") {
      return holders.filter((holder) => !addressLabels[holder.address]);
    }
    return holders;
  }, [holders, filterMode]);

  const columns = useMemo(
    () => [
      {
        header: "RANK",
        cell: (info) => {
          // Use the row's index in the table to dynamically calculate rank
          return (
            <div className="text-center font-mono text-gray-900 dark:text-gray-100">
              {info.row.index + 1}
            </div>
          );
        },
      },
      {
        accessorKey: "address",
        header: "ADDRESS",
        cell: (info) => {
          const address = info.getValue();
          const label = addressLabels[address];
          const etherscanUrl = `https://etherscan.io/address/${address}`;
          return (
            <a
              href={etherscanUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-500"
              title={address}
            >
              {label || <span className="font-mono text-sm">{address}</span>}
            </a>
          );
        },
      },
      {
        accessorKey: "current_balance",
        header: "CURRENT\nBALANCE",
        cell: (info) => (
          <div className="text-right font-mono text-gray-900 dark:text-gray-100">
            {Number(info.getValue()).toFixed(2)}
          </div>
        ),
      },
      {
        accessorKey: "week_change",
        header: "WEEK\nCHANGE",
        cell: (info) => {
          const value = info.getValue();
          return (
            <div className={`text-right font-mono ${
              value < 0 ? "text-red-500" : 
              value > 0 ? "text-green-500" : 
              "text-gray-600 dark:text-gray-400"
            }`}>
              {value.toFixed(2)}
            </div>
          );
        },
      },
      {
        accessorKey: "month_change",
        header: "MONTH\nCHANGE",
        cell: (info) => {
          const value = info.getValue();
          return (
            <div className={`text-right font-mono ${
              value < 0 ? "text-red-500" : 
              value > 0 ? "text-green-500" : 
              "text-gray-600 dark:text-gray-400"
            }`}>
              {value.toFixed(2)}
            </div>
          );
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: filteredHolders,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ id: "current_balance", desc: true }],
    },
  });

  if (isLoading) {
    return <div className="text-center text-gray-500 py-4">Loading holders data...</div>;
  }

  if (isError) {
    return <div className="text-center text-red-500 py-4">Error loading holders data: {error.message}</div>;
  }

  return (
    <div className="w-full mt-8 px-4 py-6 md:px-8">
      <div className="max-w-6xl mx-auto">
        {/* Title Card */}

        {/* Filters and Table Card */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 pb-8 overflow-hidden">
          {/* Filter Buttons */}
          <div className="flex justify-end mb-6">
            <div className="flex gap-2">
              <button
                onClick={() => setFilterMode("All")}
                className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                  filterMode === "All"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                }`}
              >
                All
              </button>
              <button
                onClick={() => setFilterMode("Ignore Protocol")}
                className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                  filterMode === "Ignore Protocol"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                }`}
              >
                Ignore Protocol
              </button>
            </div>
          </div>

          {/* Table */}
          <div className="overflow-auto max-h-[600px] rounded-lg border border-gray-100 dark:border-gray-700">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50 dark:bg-gray-700 border-b border-gray-100 dark:border-gray-600">
                <th className="sticky top-0 bg-gray-50 dark:bg-gray-700 text-xs text-gray-500 dark:text-gray-400 font-medium text-left px-4 py-3 text-center">
                    RANK
                  </th>
                  <th className="sticky top-0 bg-gray-50 dark:bg-gray-700 text-xs text-gray-500 dark:text-gray-400 font-medium text-left px-4 py-3">
                    ADDRESS
                  </th>
                  <th className="sticky top-0 bg-gray-50 dark:bg-gray-700 text-xs text-gray-500 dark:text-gray-400 font-medium text-right px-4 py-3 whitespace-pre">
                    CURRENT<br />BALANCE
                  </th>
                  <th className="sticky top-0 bg-gray-50 dark:bg-gray-700 text-xs text-gray-500 dark:text-gray-400 font-medium text-right px-4 py-3 whitespace-pre">
                    WEEK<br />CHANGE
                  </th>
                  <th className="sticky top-0 bg-gray-50 dark:bg-gray-700 text-xs text-gray-500 dark:text-gray-400 font-medium text-right px-4 py-3 whitespace-pre">
                    MONTH<br />CHANGE
                  </th>
                </tr>
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="border-b border-gray-100 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700/50"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-4 py-3 text-sm">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoldersTable;