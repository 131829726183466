import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";

const API_URL = process.env.REACT_APP_API_URL;

const fetchMints = async () => {
  const response = await fetch(`${API_URL}/mint`);
  if (!response.ok) {
    throw new Error("Failed to fetch mints data");
  }
  const jsonData = await response.json();

  // Compute cumulative totals
  let cumulativeTotal = 0;
  return jsonData.map((mint) => {
    const value = Number(mint.value);
    const safeValue = isNaN(value) ? 0 : value; // Default invalid values to 0
    cumulativeTotal += safeValue;
    return { ...mint, value: safeValue, cumulative: cumulativeTotal };
  });
};

const MintsTable = () => {
  // Fetch mints data using React Query
  const { data: mints, isLoading, isError, error } = useQuery({
    queryKey: ["mints"],
    queryFn: fetchMints,
    staleTime: 5 * 60 * 1000, // 5 minutes cache duration
    refetchInterval: 20 * 60 * 1000, // Poll every 20 minutes
  });

  // Define columns for React Table
  const columns = useMemo(
    () => [
      {
        accessorKey: "value", // Key in the data
        header: "Minted",
        cell: (info) => Number(info.getValue()).toFixed(2),
      },
      {
        accessorKey: "cumulative",
        header: "Cumulative",
        cell: (info) => Number(info.getValue()).toFixed(2),
      },
      {
        accessorKey: "timestamp",
        header: "Timestamp",
        cell: (info) => new Date(info.getValue()).toLocaleString(),
      },
    ],
    []
  );

  // Initialize React Table
  const table = useReactTable({
    data: mints || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: { pageSize: 10 },
      sorting: [{ id: "timestamp", desc: true }], // Default sort by timestamp descending
    },
  });

  if (isLoading || !mints) return <p className="text-center text-gray-500">Loading...</p>;
  if (isError) return <p className="text-center text-red-500">Error: {error.message}</p>;

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Mints</h2>
      <table className="min-w-full divide-y divide-gray-200 table-auto border border-gray-300">
        <thead className="bg-gray-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-4 py-2 text-left text-sm font-semibold text-gray-700"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="hover:bg-gray-50">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-4 py-2 text-sm text-gray-700">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
        >
          Previous
        </button>
        <div className="text-sm">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MintsTable;
