import React from 'react';

const About = () => {
  return (
    <div className="mt-8 p-6 font-sans">
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold dark:text-gray-500">Welcome to eefi.tools</h1>
        <p className="text-lg text-gray-600 dark:text-gray-400 mt-2">The community driven elastic finance protocol's insights and tools.</p>
      </header>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 dark:text-gray-500">The Protocol Itself</h2>
        <div className="space-y-2 text-gray-700 dark:text-gray-400">
          <div>
            <a
              href="https://eefi.finance/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Elastic Finance Protocol
            </a>
            {" - The inspiration for this community project"}
          </div>
          <div>
            <a
              href="https://newdocs.eefi.finance/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Documentation
            </a>
            {" - The source of truth!"}
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4 dark:text-gray-500">About Us</h2>
        <p className="text-gray-700 dark:text-gray-400 mb-2">
          <strong>eefi.tools</strong> aims to provide users with informative tools to navigate
          the ever-evolving flywheels of elastic finance. This platform is designed to empower your
          EEFI journey with data and insights.
        </p>
        <p className="text-gray-700 dark:text-gray-400">
          This app has been created by community members @silentshower and @omnifarius. Our goal is to provide
          both new and experienced users with the tools they need to make informed decisions. Mostly, we just
          wanted to share more about this protocol with the community that supports it.
        </p>
      </section>

      <footer className="text-center mt-10">
        <h3 className="text-xl font-semibold dark:text-gray-500 mb-4">Find the project!</h3>
        <div className="flex justify-center space-x-6">
          <a
            href="https://x.com/EefiFinance"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="X-Twitter"
            className="text-blue-500 hover:text-blue-600"
          >
            <i className="fab fa-x-twitter fa-2x"></i>
          </a>
          <a
            href="https://t.me/elastic_finance_community"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Telegram"
            className="text-indigo-500 hover:text-indigo-600"
          >
            <i className="fab fa-telegram fa-2x"></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default About;