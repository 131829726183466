import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const API_URL = process.env.REACT_APP_API_URL;

const fetchSupplyData = async () => {
  const response = await fetch(`${API_URL}/supply`);
  if (!response.ok) throw new Error("Failed to fetch supply data");
  return response.json();
};

const FloatChart = ({ timelineFilter }) => {
  const { data: supplyData, isLoading, isError } = useQuery({
    queryKey: ["supply"],
    queryFn: fetchSupplyData,
    staleTime: 5 * 60 * 1000,
  });

  const startDate = useMemo(() => {
    const now = new Date();
    switch (timelineFilter) {
      case "lastWeek":
        return new Date(now.setDate(now.getDate() - 7));
      case "lastMonth":
        return new Date(now.setMonth(now.getMonth() - 1));
      case "lastQuarter":
        return new Date(now.setMonth(now.getMonth() - 3));
      default:
        return null;
    }
  }, [timelineFilter]);

  const chartData = useMemo(() => {
    if (!supplyData) return [];

    return Object.entries(supplyData)
      .map(([date, buckets]) => {
        const totals = { date };
        let totalAmount = 0;

        buckets.forEach((bucket) => {
          totals[bucket.source] = bucket.amount;
          if (bucket.source === "TOTAL") totalAmount = bucket.amount;
        });

        totals["FreeFloat"] =
          totalAmount -
          (totals["PEEFI"] || 0) -
          (totals["LIQUIDITY"] || 0) -
          (totals["TREASURY"] || 0);

        return totals;
      })
      .filter((entry) => !startDate || new Date(entry.date) >= startDate);
  }, [supplyData, startDate]);

  const yDomain = useMemo(() => {
    if (!chartData.length) return [0, 1000];
    const allValues = chartData.flatMap((d) =>
      ["TOTAL", "FreeFloat", "PEEFI", "LIQUIDITY", "TREASURY"].map(
        (key) => d[key] || 0
      )
    );

    const maxValue = Math.max(...allValues);
    const minValue = Math.min(...allValues);
    const padding = maxValue * 0.02;

    return [Math.floor(minValue), Math.ceil(maxValue + padding)];
  }, [chartData]);

  function getWeekNumber(date) {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  }

  if (isLoading) {
    return <div className="text-center text-gray-500 dark:text-gray-400">Loading chart data...</div>;
  }

  if (isError) {
    return <div className="text-center text-red-500">Error loading chart data.</div>;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 10, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
        <XAxis
          dataKey="date"
          interval="preserveStartEnd"
          tickFormatter={(date) => {
            const d = new Date(date);
            
            if (timelineFilter === "lastWeek") {
              return d.toLocaleDateString("en-US", { day: "numeric", month: "short" });
            } else if (timelineFilter === "lastMonth") {
              return d.toLocaleDateString("en-US", { day: "numeric", month: "short" });
            } else if (timelineFilter === "lastQuarter") {
              return `W${getWeekNumber(d)}`;
            } else {
              return d.toLocaleDateString("en-US", { month: "short", year: "2-digit"});
            }
          }}
          stroke="#6b7280"
          fontSize={12}
        />
        <YAxis
          domain={yDomain}
          stroke="#6b7280"
          fontSize={12}
          tickFormatter={(value) => Number(value).toLocaleString()}
        />
        <Tooltip
          formatter={(value, name) => [Number(value).toLocaleString(), name]}
          labelFormatter={(label) => new Date(label).toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'short', 
            day: 'numeric' 
          })}
          contentStyle={{
            backgroundColor: "white",
            border: "none",
            borderRadius: "0.5rem",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
          }}
        />
        <Legend />

        <Area
          type="monotone"
          dataKey="TREASURY"
          stackId="1"
          stroke="#f97316"
          fill="#f97316"
          fillOpacity={0.6}
          name="Treasury"
        />
        <Area
          type="monotone"
          dataKey="LIQUIDITY"
          stackId="1"
          stroke="#3b82f6"
          fill="#3b82f6"
          fillOpacity={0.6}
          name="Liquidity"
        />
        <Area
          type="monotone"
          dataKey="PEEFI"
          stackId="1"
          stroke="#22c55e"
          fill="#22c55e"
          fillOpacity={0.6}
          name="pEEFI Pod"
        />
        <Area
          type="monotone"
          dataKey="FreeFloat"
          stackId="1"
          stroke="#8b5cf6"
          fill="#8b5cf6"
          fillOpacity={0.6}
          name="Community"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default FloatChart;