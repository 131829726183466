import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

const MermaidDiagram = ({ chartDefinition }) => {
  const ref = useRef();

  useEffect(() => {
    mermaid.initialize({ startOnLoad: true });
    mermaid.contentLoaded();
  }, [chartDefinition]);

  return (
    <div ref={ref}>
      <div className="mermaid">{chartDefinition}</div>
    </div>
  );
};

export default function App() {
  const chartDefinition = `
    flowchart TD
      A[Start] --> B{Decision}
      B -->|Yes| C(Task 1)
      B -->|No| D((Task 2))
      C --> E[End]
      D --> E[(End)]
  `;

  return <MermaidDiagram chartDefinition={chartDefinition} />;
}
