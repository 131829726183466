import React, { useState, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart
} from 'recharts';
import _ from 'lodash';

const TIME_FILTERS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

const PERIOD_FILTERS = [
  { label: '7d', value: '7d' },
  { label: '30d', value: '30d' },
  { label: '90d', value: '90d' },
  { label: 'All', value: 'all' },
];

const BurnBarChart = ({ data }) => {
  const [timeFilter, setTimeFilter] = useState('daily');
  const [periodFilter, setPeriodFilter] = useState('7d');

  const filteredData = useMemo(() => {
    if (!data || periodFilter === 'all') return data;

    const now = Date.now();
    const periodMapping = {
      '7d': 7 * 24 * 60 * 60 * 1000,
      '30d': 30 * 24 * 60 * 60 * 1000,
      '90d': 90 * 24 * 60 * 60 * 1000,
    };
    const period = periodMapping[periodFilter];

    return data.filter(item => now - new Date(item.timestamp).getTime() <= period);
  }, [data, periodFilter]);

  const aggregatedData = useMemo(() => {
    if (timeFilter === 'daily' || !filteredData) return filteredData;

    return _.chain(filteredData)
      .groupBy(item => {
        const date = new Date(item.timestamp);
        if (timeFilter === 'weekly') {
          const day = date.getDay();
          const diff = date.getDate() - day + (day === 0 ? -6 : 1);
          return new Date(date.setDate(diff)).getTime();
        } else if (timeFilter === 'monthly') {
          return new Date(date.getFullYear(), date.getMonth(), 1).getTime();
        }
      })
      .map((group, timestamp) => ({
        timestamp: Number(timestamp),
        furnaceValue: _.sumBy(group, 'furnaceValue'),
        vaultValue: _.sumBy(group, 'vaultValue'),
      }))
      .value();
  }, [filteredData, timeFilter]);

  return (
    <div className="w-full">
      <div className="flex flex-col lg:flex-row lg:justify-evenly gap-4 mb-6">
        {/* Time Filter */}
        <div className="flex gap-2 justify-evenly">
          {TIME_FILTERS.map(({ label, value }) => (
            <button
              key={value}
              onClick={() => setTimeFilter(value)}
              className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                timeFilter === value
                  ? "bg-blue-600 text-white"
                  : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
              }`}
            >
              {label}
            </button>
          ))}
        </div>
        {/* Period Filter */}
        <div className="flex gap-2 justify-evenly">
          {PERIOD_FILTERS.map(({ label, value }) => (
            <button
              key={value}
              onClick={() => setPeriodFilter(value)}
              className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                periodFilter === value
                  ? "bg-blue-600 text-white"
                  : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
              }`}
            >
              {label}
            </button>
          ))}
        </div>
      </div>
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <ComposedChart
            data={aggregatedData}
            margin={{ top: 10, right: 30, left: 40, bottom: 60 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              type="number"
              scale="time"
              domain={(() => {
                let padding;
                switch(timeFilter) {
                  case 'monthly':
                    padding = 30 * 24 * 60 * 60 * 1000;
                    break;
                  case 'weekly':
                    padding = 7 * 24 * 60 * 60 * 1000;
                    break;
                  default:
                    padding = 24 * 60 * 60 * 1000;
                }
                return [
                  `dataMin - ${padding}`,
                  `dataMax + ${padding}`
                ];
              })()}
              tickFormatter={(timestamp) => {
                const date = new Date(timestamp);
                if (timeFilter === 'monthly') {
                  return `${date.toLocaleDateString('en-US', { month: 'short', year: '2-digit' })}`;
                } else if (timeFilter === 'weekly') {
                  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                }
                return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
              }}
              height={60}
            />
            <YAxis domain={[0, 'auto']} />
            <Tooltip
              labelFormatter={(label) => {
                const date = new Date(label);
                let format = { year: 'numeric', month: 'short', day: 'numeric' };
                if (timeFilter === 'weekly') {
                  format = { ...format, weekday: 'short' };
                }
                return date.toLocaleDateString('en-US', format);
              }}
              formatter={(value) => value.toFixed(2)}
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  const furnaceValue = payload.find(p => p.name === 'Furnace')?.value || 0;
                  const vaultValue = payload.find(p => p.name === 'Vault')?.value || 0;
                  const total = furnaceValue + vaultValue;
                  
                  return (
                    <div className="bg-white p-2 border border-gray-200 shadow-sm">
                      <p className="text-gray-600">
                        {new Date(label).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          ...(timeFilter === 'weekly' ? { weekday: 'long' } : {})
                        })}
                      </p>
                      {payload.map((entry) => (
                        <p key={entry.name} style={{ color: entry.color }}>
                          {`${entry.name}: ${entry.value.toFixed(2)}`}
                        </p>
                      ))}
                      <p className="text-gray-500 border-t border-gray-200 mt-1 pt-1">
                        {`Total: ${total.toFixed(2)}`}
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Legend align="center" verticalAlign="bottom" height={36} />
            <Bar
              dataKey="furnaceValue"
              name="Furnace"
              stackId={1}
              fill="#FF0000"
              stroke="#FF0000"
              fillOpacity={0.3}
            />
            <Bar
              dataKey="vaultValue"
              name="Vault"
              stackId={1}
              fill="#FFA500"
              stroke="#FFA500"
              fillOpacity={0.3}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BurnBarChart;
