import React from 'react';
import { Handle, Position, handleParentExpand } from 'reactflow';

const PieChartNode = ({ data }) => {
  const { slices = [], size = 150 } = data; // Pie chart size and slices
  const radius = size / 2; // Radius of the pie
  const center = size / 2; // Center of the pie chart
  let cumulativePercent = 0;

  const handlePositions = [];

  const renderSlices = () => {
    return slices.map(([label, percent, color, handleDir], index) => {
      const startAngle = cumulativePercent * 2 * Math.PI;
      const endAngle = (cumulativePercent + percent) * 2 * Math.PI;

      // Midpoint angle for the handle position
      const midAngle = startAngle + (endAngle - startAngle) / 2;
      const handleX = center + radius * Math.cos(midAngle);
      const handleY = center + radius * Math.sin(midAngle);

      handlePositions.push({ id: `slice-${index}`, label: `${label}` , x: handleX, y: handleY, handleDir: `${handleDir}` });

      // Slice Path
      const largeArcFlag = percent > 0.5 ? 1 : 0;
      const x1 = center + radius * Math.cos(startAngle);
      const y1 = center + radius * Math.sin(startAngle);
      const x2 = center + radius * Math.cos(endAngle);
      const y2 = center + radius * Math.sin(endAngle);

      const pathData = `
        M ${center} ${center}
        L ${x1} ${y1}
        A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}
        Z
      `;

      cumulativePercent += percent;

      return (
        <path
          key={index}
          d={pathData}
          fill={color}
          stroke="#fff"
          strokeWidth="1"
        />
      );
    });
  };

  return (
    <div
      style={{
        position: 'relative',
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
      }}
    >
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {renderSlices()}
      </svg>

      {/* Default Top Handle */}
      <Handle
        type="target"
        position={Position.Top}
        id="top-center"
        className="invisible"
        style={{
          position: 'absolute',
          left: '50%',
          top: '0%', // Top of the circle
          transform: 'translate(-50%, 0%)',
          backgroundColor: '#333',
          width: '12px',
          height: '12px',
          border: '2px solid #fff',
          borderRadius: '50%',
        }}
        title="Top Connection"
      />


      {/* Render Handles Outside the SVG */}
      {handlePositions.map((handle) => (
        <Handle
          key={handle.id}
          type="source"
          position={handle.handleDir || Position.Top}
          id={handle.id}
          className="invisible"
          label={handle.label}
          style={{
            position: 'absolute',
            left: `${handle.x}px`,
            top: `${handle.y}px`,
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#333',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
          }}
        />
      ))}

      {/* Center Label */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontWeight: 'bold',
          color: '#ffffff',
          textAlign: 'center',
        }}
      >
        {data.label || ''}
      </div>
    </div>
  );
};

export default PieChartNode;
