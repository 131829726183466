import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const API_URL = process.env.REACT_APP_API_URL;

const fetchBurns = async () => {
  const response = await fetch(`${API_URL}/burn`);
  if (!response.ok) throw new Error("Failed to fetch burns data");
  return response.json();
};

const fetchMints = async () => {
  const response = await fetch(`${API_URL}/mint`);
  if (!response.ok) throw new Error("Failed to fetch mints data");
  return response.json();
};

// Helper function for week numbers
function getWeekNumber(date) {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}

const MintyBurnyChart = ({ timelineFilter }) => {
  const { data: burns, isLoading: loadingBurns, isError: errorBurns } = useQuery({
    queryKey: ["burns"],
    queryFn: fetchBurns,
    staleTime: 5 * 60 * 1000,
  });

  const { data: mints, isLoading: loadingMints, isError: errorMints } = useQuery({
    queryKey: ["mints"],
    queryFn: fetchMints,
    staleTime: 5 * 60 * 1000,
  });

  const startDate = useMemo(() => {
    const now = new Date();
    switch (timelineFilter) {
      case "lastWeek":
        return new Date(now.setDate(now.getDate() - 7));
      case "lastMonth":
        return new Date(now.setMonth(now.getMonth() - 1));
      case "lastQuarter":
        return new Date(now.setMonth(now.getMonth() - 3));
      default:
        return null;
    }
  }, [timelineFilter]);

  const chartData = useMemo(() => {
    if (!burns || !mints) return [];

    const combined = [
      ...burns.map((burn) => ({
        timestamp: burn.timestamp,
        value: -burn.value,
        type: "Burn",
      })),
      ...mints.map((mint) => ({
        timestamp: mint.timestamp,
        value: mint.value,
        type: "Mint",
      })),
    ];

    const sorted = combined.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    return sorted.filter((entry) => !startDate || new Date(entry.timestamp) >= startDate);
  }, [burns, mints, startDate]);

  if (loadingBurns || loadingMints) {
    return <div className="text-center text-gray-500 dark:text-gray-400">Loading chart data...</div>;
  }

  if (errorBurns || errorMints) {
    return <div className="text-center text-red-500">Error loading chart data.</div>;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={chartData} margin={{ top: 10, right: 30, left: 10, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          tickFormatter={(timestamp) => {
            const date = new Date(timestamp);
            if (timelineFilter === "lastWeek") {
              return date.toLocaleDateString("en-US", { day: "numeric", month: "short" });
            } else if (timelineFilter === "lastQuarter") {
              return `W${getWeekNumber(date)}`;
            } else if (timelineFilter === "lastMonth") {
              return date.toLocaleDateString("en-US", { day: "numeric", month: "short" });
            } else {
              return date.toLocaleDateString("en-US", { month: "short", year: "2-digit" });
            }
          }}
          stroke="#6b7280"
          fontSize={12}
        />
        <YAxis 
          stroke="#6b7280"
          fontSize={12}
          tickFormatter={(value) => Number(value).toLocaleString()}
        />
        <Tooltip
          formatter={(value) => [Number(Math.abs(value)).toLocaleString(), value > 0 ? "Minted" : "Burned"]}
          labelFormatter={(label) => new Date(label).toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'short', 
            day: 'numeric' 
          })}
          contentStyle={{
            backgroundColor: "white",
            border: "none",
            borderRadius: "0.5rem",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
          }}
        />
        <Legend />
        
        {/* Mint Bars */}
        <Bar
          dataKey={(data) => (data.type === "Mint" ? data.value : 0)}
          name="Minted"
          fill="#22c55e"
          radius={[4, 4, 0, 0]}
          stackId="1"
        />
        
        {/* Burn Bars */}
        <Bar
          dataKey={(data) => (data.type === "Burn" ? data.value : 0)}
          name="Burned"
          fill="#ef4444"
          radius={[4, 4, 0, 0]}
          stackId="1"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MintyBurnyChart;