import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";

const API_URL = process.env.REACT_APP_API_URL;

const fetchBurns = async () => {
  const response = await fetch(`${API_URL}/burn`);
  if (!response.ok) {
    throw new Error("Failed to fetch burns data");
  }
  return response.json();
};

const BurnsTable = () => {
  const { data: burns, isLoading, isError, error } = useQuery({
    queryKey: ["burns"],
    queryFn: fetchBurns,
    staleTime: 5 * 60 * 1000,
    refetchInterval: 20 * 60 * 1000,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "value",
        header: "Burnt",
        cell: (info) => Number(info.getValue()).toFixed(2),
      },
      {
        accessorKey: "type",
        header: "Source",
        cell: (info) => info.getValue(),
        filterFn: "equals",
      },
      {
        accessorKey: "timestamp",
        header: "Timestamp (UTC)",
        cell: (info) => new Date(info.getValue()).toLocaleString(),
      },
    ],
    []
  );

  const table = useReactTable({
    data: burns || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: { pageSize: 20 },
      sorting: [{ id: "timestamp", desc: true }],
    },
  });

  const handleFilterClick = (filterValue) => {
    table.getColumn("type").setFilterValue(filterValue);
  };

  const uniqueSources = Array.from(new Set(burns?.map((burn) => burn.type))) || [];

  if (isLoading) return <p className="text-center text-gray-500">Loading...</p>;
  if (isError) return <p className="text-center text-red-500">Error: {error.message}</p>;

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Burns</h2>

      {/* Source Filter Buttons */}
      <div className="mb-4 flex flex-wrap gap-2">
        <div className="text-centered flex items-center">Source:</div>
        <button
          onClick={() => handleFilterClick(undefined)}
          className={`px-3 py-1 rounded ${
            !table.getColumn("type").getFilterValue() ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          All
        </button>
        {uniqueSources.map((source) => (
          <button
            key={source}
            onClick={() => handleFilterClick(source)}
            className={`px-3 py-1 rounded ${
              table.getColumn("type").getFilterValue() === source
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            {source}
          </button>
        ))}
      </div>

      {/* Table */}
      <table className="min-w-full divide-y divide-gray-200 table-auto border border-gray-300">
        <thead className="bg-gray-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-4 py-2 text-left text-sm font-semibold text-gray-700 cursor-pointer"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getIsSorted() === "asc" && " 🔼"}
                  {header.column.getIsSorted() === "desc" && " 🔽"}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="hover:bg-gray-50">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-4 py-2 text-sm text-gray-700">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
        >
          Previous
        </button>
        <div className="text-sm">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BurnsTable;
