import React, { useState } from "react";

const ThemeSwitcher = ({ theme, toggleTheme }) => {
  const [isRotating, setIsRotating] = useState(false);

  const handleClick = () => {
    setIsRotating(true);
    toggleTheme();
    setTimeout(() => setIsRotating(false), 500);
  };

  return (
    <button
      onClick={handleClick}
      className="text-md sm:text-xl transition-all duration-500"
    >
      {theme === "light" ? (
        <i
          className={`fas fa-moon text-gray-400 hover:text-indigo-500 transition-all duration-500 ${
            isRotating ? 'transform rotate-[360deg]' : ''
          }`}
        />
      ) : (
        <i
          className={`fas fa-sun text-gray-400 hover:text-yellow-400 transition-all duration-500 ${
            isRotating ? 'transform rotate-[360deg]' : ''
          }`}
        />
      )}
    </button>
  );
};

export default ThemeSwitcher;