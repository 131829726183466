import React from 'react';
import { useQueries } from '@tanstack/react-query';

const API_URL = process.env.REACT_APP_API_URL;

// Fetch Presale Data
async function fetchPresale() {
  const response = await fetch(`${API_URL}/presale`);
  if (!response.ok) {
    throw new Error('Network response was not ok fetching presale');
  }
  return response.json();
}

// Fetch Token Data
async function fetchTokenInfo() {
  const response = await fetch(`${API_URL}/token`);
  if (!response.ok) {
    throw new Error('Network response was not ok fetching token info');
  }
  return response.json();
}

const PresaleBlock = () => {
  // Parallel Queries
  const [presaleQuery, tokenQuery] = useQueries({
    queries: [
      {
        queryKey: ['presale'],
        queryFn: fetchPresale,
        staleTime: 5 * 60 * 1000, // 5 minutes
        refetchInterval: 10 * 60 * 1000, // 10 minutes polling
      },
      {
        queryKey: ['token'],
        queryFn: fetchTokenInfo,
        staleTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
      },
    ],
  });

  // Loading states
  const isLoading = presaleQuery.isLoading || tokenQuery.isLoading;
  const isError = presaleQuery.isError || tokenQuery.isError;

  if (isLoading) {
    return <div className="text-center text-gray-500">Loading presale and token info...</div>;
  }

  if (isError) {
    return (
      <div className="text-center text-red-500">
        Error: {presaleQuery.error?.message || tokenQuery.error?.message}
      </div>
    );
  }

  // Data
  const { total, unclaimed } = presaleQuery.data;
  const { price } = tokenQuery.data;

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="flex flex-col h-full">
        <div className="bg-white shadow rounded-lg p-4 h-full min-h-[100px] min-w-[200px] flex flex-col items-center justify-between">
          <h3 className="text-lg font-semibold mb-2">Total Presale</h3>
          <p className="text-gray-700">
            {`${(total || 0).toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`} EEFI
          </p>
        </div>
      </div>
      <div className="flex flex-col h-full">
        <div className="bg-white shadow rounded-lg p-4 h-full min-h-[100px] min-w-[200px] flex flex-col items-center justify-between">
          <h3 className="text-lg font-semibold mb-2">Unclaimed</h3>
          <p className="text-gray-700">
            {`${(unclaimed || 0).toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`} EEFI
          </p>
        </div>
      </div>
      <div className="flex flex-col h-full">
        <div className="bg-white shadow rounded-lg p-4 h-full min-h-[100px] min-w-[200px] flex flex-col items-center justify-between">
          <h3 className="text-lg font-semibold mb-2">Valued</h3>
          <p className="text-gray-700">
            {`$${((unclaimed || 0) * (price || 0)).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PresaleBlock;
