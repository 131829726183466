// components/CustomRect.js
import React from "react";
import { Handle } from "reactflow";

const CustomRect = ({ data }) => {
  return (
    <div className="text-xs">
      {data.label}
      <Handle type="source" position="top" id="source-top" className="invisible" />
      <Handle type="source" position="right" id="source-right" className="invisible" />
      <Handle type="source" position="bottom" id="source-bottom" className="invisible" />
      <Handle type="source" position="left" id="source-left" className="invisible" />
      <Handle type="target" position="top" id="target-top" className="invisible" />
      <Handle type="target" position="right" id="target-right" className="invisible" />
      <Handle type="target" position="bottom" id="target-bottom" className="invisible" />
      <Handle type="target" position="left" id="target-left" className="invisible" />
    </div>
  );
};

export default CustomRect;
