import React, { useEffect, useState } from 'react';
import ReactFlow, { Background, Controls, ReactFlowProvider } from 'reactflow';
import ELK from 'elkjs/lib/elk.bundled.js';
import 'reactflow/dist/style.css';

import { defaultEdges } from './defaultEdges';
import { defaultNodes } from './defaultNodes';

import GroupNode from './GroupNode';
import FlameNode from './FlameNode';
import PieChartNode from './PieChartNode';

const nodeTypes = {
  groupNode: GroupNode,
  flameNode: FlameNode,
  pieChartNode: PieChartNode,
};

const elk = new ELK();

const FlowsElk = () => {
  const [nodes, setNodes] = useState(defaultNodes);
  const [edges] = useState(defaultEdges);

  const elkLayout = async (nodes, edges) => {
    // Transform nodes and edges to ELK's format
    const elkNodes = nodes.map((node) => ({
      id: node.id,
      width: 172,
      height: 36,
    }));

    const elkEdges = edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    }));

    const graph = {
      id: 'root',
      children: elkNodes,
      edges: elkEdges,
      layoutOptions: {
        'elk.algorithm': 'layered', // Try 'layered', 'force', 'mrtree', etc.
        'elk.direction': 'DOWN', // Layout direction: UP, DOWN, RIGHT, LEFT
        'elk.layered.spacing.nodeNodeBetweenLayers': '50',
        'elk.spacing.nodeNode': '30',
      },
    };

    // Compute the layout
    const layout = await elk.layout(graph);

    // Update node positions
    return nodes.map((node) => {
      const layoutNode = layout.children.find((n) => n.id === node.id);
      return {
        ...node,
        position: { x: layoutNode.x, y: layoutNode.y },
        draggable: true,
        positioned: true,
      };
    });
  };

  useEffect(() => {
    const layoutNodes = async () => {
      const newNodes = await elkLayout(nodes, edges);
      setNodes(newNodes);
    };
    layoutNodes();
  }, []);

  return (
    <ReactFlowProvider>
      <div style={{ height: 500 }}>
        <ReactFlow 
            nodes={nodes} 
            edges={edges} 
            nodeTypes={nodeTypes}
            fitView />
        <Background variant="dots" gap={12} size={1} />
        <Controls />
      </div>
    </ReactFlowProvider>
  );
};

export default FlowsElk;
