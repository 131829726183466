import React, { useState, useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useMediaQuery } from 'react-responsive';

const COLORS = {
  'Burned': '#ff4d00',
  'Liquidity': '#4287f5',
  'pEEFI Pod': '#40c463',
  'Treasury': '#ffbb28',
  'Unclaimed': '#ff7e67',
  'Community': '#8884d8',
  'Partners': '#9ca3af',
  'Team': '#4b5563',
  'Treasury Locked': '#374151'
};

const CenterSupply = ({ dataWithTotal }) => {
  const total = Math.floor(dataWithTotal.totalSupply).toLocaleString();
  const label = dataWithTotal.isCirculating ? 'Circulating' : 'Total';
  
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
      <div className="text-xs md:text-sm text-gray-400 dark:text-gray-400 mb-1">{label} Supply</div>
      <div className="text-lg md:text-2xl font-semibold text-gray-900 dark:text-gray-100">{total}</div>
    </div>
  );
};

const SupplyBreakdownChart = () => {

  const isMobile = useMediaQuery({ maxWidth: 500 });

  const [showTotal, setShowTotal] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/supply/breakdown`);
        if (!response.ok) throw new Error('Network response was not ok');
        const result = await response.json();
        setData(result);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Set up polling
    const interval = setInterval(fetchData, 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <div className="text-center text-gray-500">Loading supply breakdown...</div>;
  if (error) return <div className="text-center text-red-500">Error: {error}</div>;
  if (!data) return null;

  const calculatePercent = (amount, total) => (parseFloat(amount) / parseFloat(total)) * 100;

  const getChartData = () => {
    let chartData;
    let totalSupply;
    const isCirculating = !showTotal;

    if (!showTotal) {
      const baseTotal = parseFloat(data.total);
      totalSupply = baseTotal - parseFloat(data.burned);
      chartData = [
        { 
          id: 'Burned', 
          label: 'Burned', 
          value: Math.floor(parseFloat(data.burned)), 
          percent: calculatePercent(data.burned, baseTotal)
        },
        { 
          id: 'Liquidity', 
          label: 'Liquidity', 
          value: Math.floor(parseFloat(data.liquidity)), 
          percent: calculatePercent(data.liquidity, baseTotal)
        },
        { 
          id: 'pEEFI Pod', 
          label: 'pEEFI Pod', 
          value: Math.floor(parseFloat(data.peefi_pod)), 
          percent: calculatePercent(data.peefi_pod, baseTotal)
        },
        { 
          id: 'Treasury', 
          label: 'Treasury', 
          value: Math.floor(parseFloat(data.treasury)), 
          percent: calculatePercent(data.treasury, baseTotal)
        },
        { 
          id: 'Community', 
          label: 'Community', 
          value: Math.floor(parseFloat(data.community)),
          percent: calculatePercent(data.community, baseTotal)
        }
      ].filter(item => item.value > 0); // Filter out zero values
    } else {
      const baseTotal = parseFloat(data.total) + 
        parseFloat(data.partners || 0) + 
        parseFloat(data.team_locked || 0) + 
        parseFloat(data.treasury_locked || 0);
      totalSupply = baseTotal - parseFloat(data.burned);
      
      chartData = [
        { 
          id: 'Burned', 
          label: 'Burned', 
          value: Math.floor(parseFloat(data.burned)), 
          percent: calculatePercent(data.burned, baseTotal)
        },
        { 
          id: 'Liquidity', 
          label: 'Liquidity', 
          value: Math.floor(parseFloat(data.liquidity)), 
          percent: calculatePercent(data.liquidity, baseTotal)
        },
        { 
          id: 'pEEFI Pod', 
          label: 'pEEFI Pod', 
          value: Math.floor(parseFloat(data.peefi_pod)), 
          percent: calculatePercent(data.peefi_pod, baseTotal)
        },
        { 
          id: 'Treasury', 
          label: 'Treasury', 
          value: Math.floor(parseFloat(data.treasury)), 
          percent: calculatePercent(data.treasury, baseTotal)
        },
        { 
          id: 'Unclaimed', 
          label: 'Unclaimed', 
          value: Math.floor(parseFloat(data.unclaimed)), 
          percent: calculatePercent(data.unclaimed, baseTotal)
        },
        { 
          id: 'Community', 
          label: 'Community', 
          value: Math.floor(parseFloat(data.community)),
          percent: calculatePercent(data.community, baseTotal)
        },
        { 
          id: 'Team', 
          label: 'Team', 
          value: Math.floor(parseFloat(data.team_locked || 0)), 
          percent: calculatePercent(data.team_locked || 0, baseTotal)
        },
        { 
          id: 'Partners', 
          label: 'Partners', 
          value: Math.floor(parseFloat(data.partners || 0)), 
          percent: calculatePercent(data.partners || 0, baseTotal)
        },
        { 
          id: 'Treasury Locked', 
          label: 'Treasury Locked', 
          value: Math.floor(parseFloat(data.treasury_locked || 0)), 
          percent: calculatePercent(data.treasury_locked || 0, baseTotal)
        }
      ].filter(item => item.value > 0); // Filter out zero values
    }

    return { chartData, totalSupply, isCirculating };
  };

  const { chartData, totalSupply, isCirculating } = getChartData();

  const chartMargin = isMobile
    ? { top: 20, right: 20, bottom: 20, left: 20 }
    : { top: 40, right: 120, bottom: 40, left: 120 };

  const chartInnerRadius = isMobile ? 0.5 : 0.6;

  return (
    <div className="w-full">
      <div className="flex justify-center mb-4">
        <div className="inline-flex rounded-lg border border-gray-200 dark:border-gray-600 p-1 sm:p-0">
          <button
            onClick={() => setShowTotal(false)}
            className={`px-2 sm:px-4 py-1 sm:py-2 rounded-lg text-sm sm:text-base font-medium transition-colors
              ${!showTotal 
                ? 'bg-blue-500 text-white' 
                : 'text-gray-600 hover:bg-gray-100'}`}
          >
            Circulating
          </button>
          <button
            onClick={() => setShowTotal(true)}
            className={`px-2 sm:px-4 py-1 sm:py-2 rounded-lg text-sm sm:text-base font-medium transition-colors
              ${showTotal 
                ? 'bg-blue-500 text-white' 
                : 'text-gray-600 hover:bg-gray-100'}`}
          >
            Total
          </button>
        </div>
      </div>
      <div className="w-full h-[400px] relative">
        <ResponsivePie
          data={chartData}
          //margin={{ top: 40, right: 120, bottom: 40, left: 120 }}
          margin={chartMargin}
          //innerRadius={0.6}
          innerRadius={chartInnerRadius}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={({ id }) => COLORS[id]}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]]
          }}
          enableArcLabels={isMobile}
          enableArcLinkLabels={!isMobile}
          arcLabelsRadiusOffset={isMobile ? 0.5 : 0.7} // Adjust radius for mobile
          arcLabelsTextColor={isMobile ? 'white' : { from: 'color', modifiers: [['darker', 2]] }} // White text for mobile
          arcLabel={(d) => `${d.label}`}
          arcLinkLabelsSkipAngle={1}
          arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLinkLabelsDiagonalLength={36}
          arcLinkLabelsStraightLength={24}
          motionConfig="gentle"
          transitionMode="startAngle"
          tooltip={({ datum }) => (
            <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-200 font-sans text-sm">
              <div className="font-medium mb-1">{datum.label}</div>
              <div className="text-gray-600">
                <div>Amount: {new Intl.NumberFormat().format(datum.value)}</div>
                <div>Percentage: {datum.data.percent.toFixed(2)}%</div>
              </div>
            </div>
          )}
        />
        <CenterSupply 
          dataWithTotal={{ 
            totalSupply, 
            isCirculating 
          }} 
        />
      </div>
    </div>
  );
};

export default SupplyBreakdownChart;