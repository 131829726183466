import { Position } from 'reactflow';

export const defaultNodes = [
  {
    id: 'furnace',
    position: { x: 600, y: 950 },
    draggable: true,
    data: { label: 'Furnace' },
    type: 'flameNode',
    className: 'p-4 border rounded shadow-md text-center',
  },
  {
    id: 'vault',
    position: { x: 600, y: 50 },
    draggable: true,
    data: { label: 'Elastic Vault (AMPL)' },
    type: 'customRect',
    className: 'bg-blue-200 p-3 border rounded shadow-md text-center',
  },
  {
    id: 'rebase',
    position: { x: 600, y: 150 },
    draggable: true,
    data: { label: 'AMPL Rebase' },
    type: 'customRect',
    className: 'bg-blue-200 p-3 border rounded shadow-md text-center',
  },
  {
    id: 'r_pos',
    position: { x: 775, y: 250 },
    draggable: true,
    data: { label: 'Positive' },
    type: 'customRect',
    className: 'bg-blue-200 p-3 border rounded shadow-md text-center',
  },
  {
    id: 'r_equ',
    position: { x: 650, y: 250 },
    draggable: true,
    data: { label: 'Equilibrium' },
    type: 'customRect',
    className: 'bg-blue-200 p-3 border rounded shadow-md text-center',
  },
  {
    id: 'r_neg',
    position: { x: 500, y: 250 },
    draggable: true,
    data: { label: 'Negative' },
    type: 'customRect',
    className: 'bg-blue-200 p-3 border rounded shadow-md text-center',
  },
  {
    id: 'lp_header',
    position: { x: 50, y: 150 },
    draggable: true,
    data: { label: 'Protocol Owned Liquidity' },
    className: 'bg-gray-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'lp_eo',
    position: { x: 50, y: 250 },
    draggable: true,
    data: { label: 'EEFI/OHM UniV2' },
    className: 'bg-gray-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'lp_ew',
    position: { x: 50, y: 350 },
    draggable: true,
    data: { label: 'EEFI/WETH UniV3' },
    className: 'bg-gray-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'lp_es',
    position: { x: 50, y: 450 },
    draggable: true,
    data: { label: 'EEFI/SPOT UniV3' },
    className: 'bg-gray-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'lp_ewa',
    position: { x: 50, y: 550 },
    draggable: true,
    data: { label: 'EEFI/WAMPL UniV3' },
    className: 'bg-gray-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'lp_fees',
    position: { x: 250, y: 400 },
    draggable: true,
    data: { label: 'Liquidity Fees' },
    className: 'bg-gray-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'lp_usdcspot',
    position: { x: 50, y: 650 },
    draggable: true,
    data: { label: 'USDC/SPOT UniV3' },
    className: 'bg-gray-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'vault_depositors',
    position: { x: 325, y: 100 },
    draggable: true,
    data: { label: 'Vault Depositors' },
    className: 'bg-blue-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'rewards_pool',
    position: { x: 525, y: 450 },
    draggable: true,
    data: { label: 'Rewards Pool' },
    className: 'bg-purple-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'treasury',
    position: { x: 925, y: 750 },
    draggable: true,
    data: { label: 'DAO Treasury' },
    type: 'customRect',
    className: 'bg-green-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'pie_r_pos',
    position: { x: 850, y: 350 },
    draggable: true,
    type: 'pieChartNode',
    data: {
      label: 'Rebase Distribution',
      size: 150,
      slices: [
        ['Buy OHM', 0.22, '#000080', Position.Bottom], // 22% buys OHM 
        ['Buy EEFI', 0.45, '#1E90FF', Position.Left], // 45% buys EEFI to burn
        ['Stays in Vault', 0.3, '#87CEEB', Position.Top], // 30% stays in the vault
        ['Treasury', 0.03, '#ADD8E6', Position.Right], // 3% goes to treasury
      ],
    },
  },
  {
    id: 'mint_eefi',
    position: { x: 550, y: 350 },
    draggable: true,
    data: { label: 'Mint EEFI' },
    type: 'customRect',
    className: 'bg-blue-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'buy_eefi',
    position: { x: 600, y: 900 },
    draggable: true,
    type: 'customRect',
    data: { label: 'Market Buy EEFI' },
    className: 'bg-red-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'buy_ohm',
    position: { x: 900, y: 550 },
    draggable: true,
    data: { label: 'Buy OHM' },
    type: 'customRect',
    className: 'bg-blue-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'ampl_reserved',
    position: { x: 850, y: 100 },
    draggable: true,
    data: { label: 'AMPL Reserved' },
    className: 'bg-blue-200 p-4 border rounded shadow-md text-center',
    type: 'customRect'
  },
  {
    id: 'vault_ampl_fee',
    position: { x: 1000, y: 550 },
    draggable: true,
    data: { label: 'AMPL Fee' },
    type: 'customRect',
    className: 'bg-blue-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'growth_assets',
    position: { x: 1200, y: 300 },
    draggable: true,
    data: { label: 'Growth Assets' },
    type: 'customRect',
    className: 'bg-green-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'incoming_fees',
    position: { x: 1200, y: 450 },
    draggable: true,
    data: { label: 'Incoming Fees/Proceeds' },
    type: 'customRect',
    className: 'bg-green-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'treasury_ops',
    position: { x: 750, y: 825 },
    draggable: true,
    data: { label: 'Treasury Operations' },
    type: 'customRect',
    className: 'bg-green-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'bond_buyer',
    position: { x: 1400, y: 100 },
    draggable: true,
    type: 'customRect',
    data: { label: 'Bond Buyer' },
    className: 'bg-yellow-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'bond_manager',
    position: { x: 1400, y: 200 },
    draggable: true,
    type: 'customRect',
    data: { label: 'Bond Manager' },
    className: 'bg-yellow-200 p-4 border rounded shadow-md text-center',
  },
  {
    id: 'discounted_eefi',
    position: { x: 1550, y: 150 },
    draggable: true,
    type: 'customRect',
    data: { label: 'Discounted EEFI' },
    className: 'bg-yellow-200 p-4 border rounded shadow-md text-center',
  }
];