import React, { useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

const UniswapPositions = ({ liquidity }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'pool',
        header: 'Pool',
        cell: (info) => (
          <div className="font-medium text-center">
            {info.getValue()}
          </div>
        ),
      },
      {
        accessorKey: 'liquidity',
        header: 'Liquidity',
        cell: (info) => (
          <div className="flex flex-col items-center space-y-1">
            {Object.entries(info.getValue()).map(([token, amount]) => (
              <div key={token} className="font-mono text-center">
                <span className="text-gray-600">{token}:</span>{' '}
                {amount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            ))}
          </div>
        ),
      },
      {
        accessorKey: 'value',
        header: 'Value',
        cell: (info) => (
          <div className="text-center font-mono">
            <span className="text-gray-500">$</span>
            <span className="font-medium">
              {info.getValue().toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: liquidity,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="overflow-x-auto rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
        <thead className="bg-gray-50 darj:bg-gray-700 text-gray-500 dark:text-gray-400">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-6 py-3 text-center text-sm font-semibold text-gray-500 dark:text-gray-400 bg-gray dark:bg-gray-700 uppercase tracking-wider"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="">
          {table.getRowModel().rows.map((row) => (
            <tr 
              key={row.id} 
              className="border-b border-gray-100 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700/50"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="px-6 py-4 text-sm dark:text-gray-400"
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UniswapPositions;