import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from "recharts";

const API_URL = process.env.REACT_APP_API_URL;

const fetchBurns = async () => {
  const response = await fetch(`${API_URL}/burn`);
  if (!response.ok) throw new Error("Failed to fetch burns data");
  return response.json();
};

const fetchMints = async () => {
  const response = await fetch(`${API_URL}/mint`);
  if (!response.ok) throw new Error("Failed to fetch mints data");
  return response.json();
};

const SupplyLineChart = ({ timelineFilter }) => {
  const { data: burns, isLoading: loadingBurns, isError: errorBurns } = useQuery({
    queryKey: ["burns"],
    queryFn: fetchBurns,
    staleTime: 5 * 60 * 1000,
  });

  const { data: mints, isLoading: loadingMints, isError: errorMints } = useQuery({
    queryKey: ["mints"],
    queryFn: fetchMints,
    staleTime: 5 * 60 * 1000,
  });

  const startDate = useMemo(() => {
    const now = new Date();
    switch (timelineFilter) {
      case "lastWeek":
        return new Date(now.setDate(now.getDate() - 7));
      case "lastMonth":
        return new Date(now.setMonth(now.getMonth() - 1));
      case "lastQuarter":
        return new Date(now.setMonth(now.getMonth() - 3));
      default:
        return null;
    }
  }, [timelineFilter]);    

  const chartData = useMemo(() => {
    if (!burns || !mints) return [];

    const combined = [
      ...burns.map(burn => ({
        timestamp: burn.timestamp,
        value: -burn.value,
      })),
      ...mints.map(mint => ({
        timestamp: mint.timestamp,
        value: mint.value,
      })),
    ];

    const sorted = combined.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    let cumulative = 0;
    const cumulativeData = sorted.map(entry => {
      cumulative += entry.value;
      return { timestamp: entry.timestamp, cumulative };
    });

    if (startDate) {
      return cumulativeData.filter(entry => new Date(entry.timestamp) >= startDate);
    }
    return cumulativeData;
  }, [burns, mints, startDate]);

  const yDomain = useMemo(() => {
    if (!chartData.length) return [0, 1000];
    const values = chartData.map(d => d.cumulative);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const padding = (max - min) * 0.1;
    return [Math.floor(min - padding), Math.ceil(max + padding)];
  }, [chartData]);

  if (loadingBurns || loadingMints) {
    return <div className="text-center text-gray-500 dark:text-gray-400">Loading chart data...</div>;
  }

  if (errorBurns || errorMints) {
    return <div className="text-center text-red-500">Error loading chart data.</div>;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={chartData} margin={{ top: 10, right: 30, left: 10, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
        <XAxis
          dataKey="timestamp"
          tickFormatter={(timestamp) => {
            const date = new Date(timestamp);
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          }}
          stroke="#6b7280"
          fontSize={12}
        />
        <YAxis
          domain={yDomain}
          stroke="#6b7280"
          fontSize={12}
          tickFormatter={(value) => Number(value).toLocaleString()}
        />
        <Tooltip
          formatter={(value) => [Number(value).toLocaleString(), "Supply"]}
          labelFormatter={(label) => new Date(label).toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'short', 
            day: 'numeric' 
          })}
          contentStyle={{
            backgroundColor: "white",
            border: "none",
            borderRadius: "0.5rem",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
          }}
        />
        <Line
          type="monotone"
          dataKey="cumulative"
          stroke="#4f46e5"
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 4, fill: "#4f46e5" }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SupplyLineChart;