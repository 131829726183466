import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import StyledDataTable from "../templates/StyledDataTable";

const BurnMetrics = () => {
  const { data: burns, isLoading: loadingBurns, isError: errorBurns } = useQuery({
    queryKey: ["burns"],
    queryFn: async () => {
      const response = await fetch("https://api.eefi.tools/burn");
      if (!response.ok) throw new Error("Failed to fetch burn data");
      return response.json();
    },
    staleTime: 5 * 60 * 1000,
  });

  const { data: tokenData, isLoading: loadingToken, isError: errorToken } = useQuery({
    queryKey: ["token"],
    queryFn: async () => {
      const response = await fetch("https://api.eefi.tools/token");
      if (!response.ok) throw new Error("Failed to fetch token data");
      return response.json();
    },
    staleTime: 5 * 60 * 1000,
  });

  const START_DATE = new Date("2024-03-06T16:48:00Z"); // All-time burns start date
  const daysSince = (startDate) => {
    const now = new Date();
    const timeDifference = now - startDate; // Difference in milliseconds
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days
  };
  const daysSinceStart = daysSince(START_DATE);

  if (loadingBurns || loadingToken) return <div className="mt-8">Loading Burns and Token Data ...</div>;
  if (errorBurns || errorToken) return <div className="mt-8">Error Loading Data.</div>;

    // Calculate metrics for different timeframes
    const timeframes = [
        { label: "7d", days: 7 },
        { label: "30d", days: 30 },
        { label: "90d", days: 90 },
        { label: "ALL", days: daysSinceStart },
    ];
    
  // Helper function to calculate burns by source
  const calculateBurnsBySource = (data, days) => {
    const now = new Date();
    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.timestamp);
      return (now - itemDate) / (1000 * 60 * 60 * 24) <= days;
    });
  
    // Explicitly calculate FURNACE and VAULT burns
    const furnaceBurn = filteredData
      .filter((item) => item.type === "FURNACE")
      .reduce((sum, item) => sum + item.value, 0);
  
    const vaultBurn = filteredData
      .filter((item) => item.type === "VAULT")
      .reduce((sum, item) => sum + item.value, 0);
  
    // Calculate total and average burns
    const totalBurn = furnaceBurn + vaultBurn;
    const averageBurn = totalBurn / (days || filteredData.length || 1);
  
    return { furnaceBurn, vaultBurn, totalBurn, averageBurn };
  };

  const burnsBySource = timeframes.map(({ label, days }) => {
    const { furnaceBurn, vaultBurn, averageBurn } = calculateBurnsBySource(burns, days);
    return {
      label,
      furnace: furnaceBurn,
      vault: vaultBurn,
      avg: averageBurn,
    };
  });

  const calculateCumulativeBurns = (data, days) => {
    const now = new Date();
    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.timestamp);
      return (now - itemDate) / (1000 * 60 * 60 * 24) <= days;
    });

    return filteredData.reduce((sum, item) => sum + item.value, 0);
  };

  const cumulativeBurns = timeframes.map(({ label, days }) => {
    const totalBurn = calculateCumulativeBurns(burns, days);
    const burnValueUSD = totalBurn * tokenData.price;
    const burnPercentage = (totalBurn / (tokenData.supply + totalBurn)) * 100;

    return {
      label,
      totalBurn,
      burnValueUSD,
      burnPercentage,
    };
  });

  const comboData = burnsBySource.map((item, index) => {
        return {
            ...item,
            ...cumulativeBurns[index],
        };
    });

  const burnsBySourceColumns = [
    { accessorKey: "label", header: "TIME" },
    { accessorKey: "furnace", header: "FURNACE", 
        formatter: (value) => value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }), },
    { accessorKey: "vault", header: "VAULT",
        formatter: (value) => value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }), },
    { accessorKey: "avg", header: "Σμ",
        formatter: (value) => value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }), },
  ];

  const cumulativeBurnsColumns = [
    { accessorKey: "label", header: "TIME" },
    { accessorKey: "totalBurn", header: "EEFI",
       formatter: (value) => value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }), },
    { accessorKey: "burnValueUSD", header: "$USD",
        formatter: (value) => `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`, },
    { accessorKey: "burnPercentage", header: "🔥%",
        formatter: (value) => `${value.toFixed(2)}%`, },
  ];

  const comboColumns = [
    { accessorKey: "label", header: "TIME" },
    { accessorKey: "furnace", header: "FURNACE", 
        formatter: (value) => value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }), },
    { accessorKey: "vault", header: "VAULT",
        formatter: (value) => value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }), },
    { accessorKey: "totalBurn", header: "Σ EEFI",
        formatter: (value) => value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }), },
    { accessorKey: "avg", header: "AVG/D",
        formatter: (value) => value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }), },
    { accessorKey: "burnValueUSD", header: "$USD",
        formatter: (value) => `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`, },
    { accessorKey: "burnPercentage", header: "🔥%",
        formatter: (value) => `${value.toFixed(2)}%`, },
  ]

  return (
    <div className="mt-10">
    <div className="p-4 mb-6 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
      <h2 className="text-lg mb-4 font-bold text-center text-gray-800 dark:text-gray-600">Burn Summary Metrics</h2>
        <div className="lg:hidden">
            <StyledDataTable data={burnsBySource} columns={burnsBySourceColumns} />
            <StyledDataTable data={cumulativeBurns} columns={cumulativeBurnsColumns} />
        </div>
        <div className="hidden lg:block">
          <StyledDataTable data={comboData} columns={comboColumns} />
        </div>
      </div>
    </div>
  );
};

export default BurnMetrics;
