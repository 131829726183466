import React, { useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  applyEdgeChanges,
  applyNodeChanges
} from 'reactflow';

import 'reactflow/dist/style.css';
import '../../index.css'; // Ensure Tailwind is configured

import { defaultNodes as initialNodes } from './defaultNodes';
import { defaultEdges as initialEdges } from './defaultEdges';

import GroupNode from './GroupNode';
import CustomRect from './customRect';
import FlameNode from './FlameNode';
import PieChartNode from './PieChartNode';

const nodeTypes = {
  groupNode: GroupNode,
  flameNode: FlameNode,
  pieChartNode: PieChartNode,
  customRect: CustomRect
};

const OverallFlows = () => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  // Handle node drag stop to update node positions
  const handleNodeDragStop = (event, node) => {
    setNodes((nds) =>
      nds.map((n) =>
        n.id === node.id
          ? { ...n, position: { x: node.position.x, y: node.position.y } }
          : n
      )
    );
  };

  // Handle node changes
  const handleNodesChange = (changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  };

  // Handle edge changes
  const handleEdgesChange = (changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  };

  return (
    <div className="mt-8 h-screen">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onNodeDragStop={handleNodeDragStop}
        nodeTypes={nodeTypes}
        fitView
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default OverallFlows;
