import { Tab, TabGroup, TabList } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import ThemeSwitcher from "./ThemeSwitcher";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FloatingNav = ({ theme, toggleTheme }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const tabs = [
    { name: "Tokenomics", route: "/", icon: "fa-coins" },
    { name: "Treasury", route: "/treasury", icon: "fa-money-bill" },
    { name: "Charts", route: "/charts", icon: "fa-fire" },
    { name: "Supply", route: "/supply", icon: "fa-chart-simple" },
    { name: "Holders", route: "/holders", icon: "fa-users" },
    { name: "Flows", route: "/flows", icon: "fa-random" },
    { name: "About", route: "/about", icon: "fa-info-circle" },
  ];

  const activeIndex = tabs.findIndex((tab) => tab.route === location.pathname);

  return (
    <>
    {/* Non-Mobile Theme Switcher */}
    <div className="hidden sm:flex sm:fixed right-3 top-3 sm:top-2 z-50">
      <ThemeSwitcher theme={theme} toggleTheme={toggleTheme} />
    </div>

    <div className="fixed left-0 sm:left-1/2 sm:transform sm:-translate-x-1/2 z-40 w-full sm:w-auto sm:mt-2">
      <div className="relative flex flex-col items-center">
        <TabGroup
          defaultIndex={activeIndex >= 0 ? activeIndex : 0}
          onChange={(index) => {
            if (index < tabs.length) {
              navigate(tabs[index].route);
            }
          }}
        >
          <TabList className="flex justify-between px-4 py-2 bg-white dark:bg-gray-900 w-full rounded-lg relative shadow-lg">
            {tabs.map((tab, index) => (
              <Tab
                key={tab.name}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className="relative group px-2 py-1 sm:py-2 transition duration-300 focus:outline-none"
              >
                {({ selected }) => (
                  <div className="w-6 sm:w-8 h-6 sm:h-8 flex items-center justify-center">
                    <i
                      className={classNames(
                        "fa-solid",
                        tab.icon,
                        selected || hoveredIndex === index
                          ? "text-indigo-500"
                          : "text-gray-300",
                        hoveredIndex === index
                          ? "text-xl sm:text-2xl transform scale-125"
                          : hoveredIndex === index - 1 || hoveredIndex === index + 1
                          ? "text-lg transform scale-110"
                          : "text-sm sm:text-md",
                        "transition-all duration-300 ease-in-out"
                      )}
                    />
                  </div>
                )}
              </Tab>
            ))}

            {/* Mobile-only theme switcher */}
            <Tab
              key="theme-switcher"
              as="div" // Render as a div to avoid button nesting issues
              className="relative group px-2 py-1 sm:py-2 transition duration-300 focus:outline-none sm:hidden"
            >
              <ThemeSwitcher theme={theme} toggleTheme={toggleTheme} />
            </Tab>

          </TabList>
        </TabGroup>
      </div>
    </div>
  </>
  );
};

export default FloatingNav;