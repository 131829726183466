import React, { useMemo } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';

const StyledDataTable = ({ data, columns }) => {
  const table = useReactTable({
    data,
    columns: columns.map((col) => ({
        ...col,
        cell: (info) => {
          // Apply formatter if defined, otherwise render raw value
          const value = info.getValue();
          return col.formatter ? col.formatter(value) : value;
        },
      })),  
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="mb-4 overflow-x-auto rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm font-sans">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
        <thead className="bg-gray-50 dark:bg-gray-700 text-gray-500 dark:text-gray-400">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="border-gray-100 dark:border-gray-600">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-2 py-2 text-center text-sm font-semibold text-gray-500 dark:text-gray-400 tracking-wider"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="border-b border-gray-100 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700/50"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="px-2 py-2 text-center text-sm dark:text-gray-400"
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StyledDataTable;
