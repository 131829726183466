import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

const API_URL = process.env.REACT_APP_API_URL;

const fetchBurns = async () => {
  const response = await fetch(`${API_URL}/burn`);
  if (!response.ok) throw new Error("Failed to fetch burns data");
  return response.json();
};

const fetchMints = async () => {
  const response = await fetch(`${API_URL}/mint`);
  if (!response.ok) throw new Error("Failed to fetch mints data");
  return response.json();
};

const MintyBurnyTable = () => {
  const { data: burns, isLoading: loadingBurns, isError: errorBurns } = useQuery({
    queryKey: ["burns"],
    queryFn: fetchBurns,
    staleTime: 5 * 60 * 1000,
  });

  const { data: mints, isLoading: loadingMints, isError: errorMints } = useQuery({
    queryKey: ["mints"],
    queryFn: fetchMints,
    staleTime: 5 * 60 * 1000,
  });

  const combinedData = useMemo(() => {
    if (!burns || !mints) return [];
    
    // Create combined array with proper value signs
    const burnEntries = burns.map(item => ({
      ...item,
      type: "Burn",
      value: -Math.abs(item.value) // Ensure burns are negative
    }));
    
    const mintEntries = mints.map(item => ({
      ...item,
      type: "Mint",
      value: Math.abs(item.value) // Ensure mints are positive
    }));
    
    // Combine and sort by timestamp (oldest first)
    const merged = [...burnEntries, ...mintEntries].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );

    // Calculate running total starting from oldest to newest
    let runningTotal = 0;
    const processedData = merged.map(entry => {
      runningTotal += entry.value;
      return {
        ...entry,
        cumulative: runningTotal
      };
    });

    // Return the data sorted newest first for display
    return processedData.reverse();
  }, [burns, mints]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "timestamp",
        header: "DATE",
        cell: (info) => new Date(info.getValue()).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }),
      },
      {
        accessorKey: "type",
        header: "TYPE",
        cell: (info) => {
          const type = info.getValue();
          return (
            <div className="text-center font-mono">
            <span className={type === "Burn" ? "text-red-500" : "text-green-500"}>
              {type}
            </span>
            </div>
          );
        },
      },
      {
        accessorKey: "value",
        header: "AMOUNT",
        cell: (info) => (
          <div className="text-center font-mono">
            {Math.abs(Number(info.getValue())).toFixed(2)}
          </div>
        ),
      },
      {
        accessorKey: "cumulative",
        header: "TOTAL",
        cell: (info) => (
          <div className="text-center font-mono">
            {Number(info.getValue()).toFixed(2)}
          </div>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: combinedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (loadingBurns || loadingMints) {
    return <div className="text-center text-gray-500 dark:text-gray-400">Loading table data...</div>;
  }

  if (errorBurns || errorMints) {
    return <div className="text-center text-red-500">Error loading table data.</div>;
  }

  return (
    <div className="overflow-auto max-h-[600px] rounded-lg border border-gray-100 dark:border-gray-700">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-50 dark:bg-gray-700 border-b border-gray-100 dark:border-gray-600 text-center">
            {table.getHeaderGroups().map((headerGroup) => (
              headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="sticky top-0 bg-gray-50 dark:bg-gray-700 text-xs text-gray-500 dark:text-gray-400 font-medium text-center px-4 py-3 cursor-pointer"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div className="flex text-center items-center justify-center space-x-1">
                    <span>{header.column.columnDef.header}</span>
                    {header.column.getIsSorted() && (
                      <span className="text-gray-400">
                        {header.column.getIsSorted() === "desc" ? " ↓" : " ↑"}
                      </span>
                    )}
                  </div>
                </th>
              ))
            ))}
          </tr>
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="border-b border-gray-100 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700/50"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-4 py-3 text-sm text-gray-700 dark:text-gray-300">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MintyBurnyTable;