import React, { useState } from "react";
import MintyBurnyTable from "./MintyBurnyTable";
import MintyBurnyChart from "./MintyBurnyChart";
import SupplyLineChart from "./SupplyLineChart";
import FloatChart from "./FloatChart";

const MintyBurnyPage = () => {
  const [timelineFilter, setTimelineFilter] = useState("lastMonth");
  
  const filterOptions = [
    { value: "all", label: "All" },
    { value: "lastQuarter", label: "↩️ Quarter" },
    { value: "lastMonth", label: "↩️ Month" },
    { value: "lastWeek", label: "↩️ Week" }
  ];

  return (
    <div className="w-full mt-8 px-4 py-6 md:px-8">
      <div className="max-w-6xl mx-auto">
        {/* Sticky container for filters */}
        <div className="sticky top-[5rem] z-10 mb-4">
          {/* Filter Buttons - Made Compact */}
          <div className="flex justify-center">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm mx-auto">
              <div className="px-6 py-2">
                <div className="flex gap-2">
                  {filterOptions.map(({ value, label }) => (
                    <button
                      key={value}
                      onClick={() => setTimelineFilter(value)}
                      className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                        timelineFilter === value
                          ? "bg-blue-600 text-white"
                          : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                      }`}
                    >
                      {label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Supply Line Chart */}
        <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 pb-8 overflow-hidden">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4 text-center">
            Supply Over Time
          </h3>
          <div className="w-full h-[400px]">
            <SupplyLineChart timelineFilter={timelineFilter} />
          </div>
        </div>

        {/* Minty Burny Chart */}
        <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 pb-8 overflow-hidden">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4 text-center">
            Mint and Burn Activity
          </h3>
          <div className="w-full h-[400px]">
            <MintyBurnyChart timelineFilter={timelineFilter} />
          </div>
        </div>

        {/* Minty Burny Table */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 pb-8 overflow-hidden">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4 text-center">
            Detailed Activity
          </h3>
          <MintyBurnyTable timelineFilter={timelineFilter} />
        </div>
      </div>
    </div>
  );
};

export default MintyBurnyPage;