import React from 'react';
import { Handle } from 'reactflow';

const FlameNode = ({ data }) => {
  return (
    <div style={{ 
      position: 'relative', 
      width: '120px', 
      height: '120px',
      draggable: 'true',
  }}>
      <svg
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
      >
        {/* Outer Flame */}
        <path
          d="
          M32 4 
          C28 12, 16 20, 16 32 
          A16 16 0 0 0 48 32 
          C48 20, 36 12, 32 4 
          Z"
          fill="#FF6F00"
          stroke="#D84315"
          strokeWidth="2"
        />
        {/* Inner Flame */}
        <path
          d="
          M32 16 
          C30 20, 24 24, 24 32 
          A8 8 0 0 0 40 32 
          C40 24, 34 20, 32 16 
          Z"
          fill="#FF8F00"
          stroke="#D84315"
          strokeWidth="1"
        />
        {/* Flame Core */}
        <path
          d="
          M32 28 
          C30 30, 28 32, 28 36 
          A4 4 0 0 0 36 36 
          C36 32, 34 30, 32 28 
          Z"
          fill="#FFCA28"
        />
      </svg>
      {/* Optional Node Label */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          fontWeight: 'normal',
          textAlign: 'center',
        }}
      >
        {data.label || ''}
      </div>
      <Handle type="target" position="top" id="target-top" className="" />
    </div>
  );
};

export default FlameNode;
