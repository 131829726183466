import React, { useCallback, useEffect } from 'react';
import ReactFlow, { 
  Background, 
  Controls, 
  useNodesState, 
  useEdgesState,
  applyNodeChanges,
  applyEdgeChanges, 
  ReactFlowProvider } from 'reactflow';
import dagre from 'dagre';

import { defaultEdges } from './defaultEdges';
import { defaultNodes } from './defaultNodes';

import GroupNode from './GroupNode';
import FlameNode from './FlameNode';
import PieChartNode from './PieChartNode';

const nodeTypes = {
  groupNode: GroupNode,
  flameNode: FlameNode,
  pieChartNode: PieChartNode,
};

const nodeWidth = 150;
const nodeHeight = 50;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const { x, y } = dagreGraph.node(node.id);
    return { 
      ...node, 
      position: { x, y }, 
      draggable: true,
      positioned: true, // Mark node as positioned
    };
  });

  return layoutedNodes;
};

const FlowsDagre = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges);

  const layoutFlow = useCallback(() => {
    const layoutedNodes = getLayoutedElements(defaultNodes, defaultEdges);
    setNodes(layoutedNodes);
    setEdges(defaultEdges);
  }, []);

  //const [nodes, setNodes] = useState(initialNodes);
  //const [edges, setEdges] = useState(initialEdges);

  // Handle node drag stop to update node positions
  const handleNodeDragStop = (event, node) => {
    setNodes((nds) =>
      nds.map((n) =>
        n.id === node.id
          ? { ...n, position: { x: node.position.x, y: node.position.y } }
          : n
      )
    );
  };
  
  // Handle node changes
  const handleNodesChange = (changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  };

  // Handle edge changes
  const handleEdgesChange = (changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  };
  


  //useEffect(() => {
  //  layoutFlow();
  //}, [layoutFlow]);

  useEffect(() => {
    const layoutedNodes = getLayoutedElements(defaultNodes, defaultEdges);
    setNodes(layoutedNodes);
  }, [setNodes]);

  return (
    <ReactFlowProvider>
      <div style={{ width: '100%', height: '90vh', border: '1px solid lightgray' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          fitView
        />
        <Background variant="dots" gap={12} size={1} />
        <Controls />
      </div>
    </ReactFlowProvider>
  );
};

export default FlowsDagre;
