export const defaultEdges = [

    { id: 'e-lp_eo-lp_fees', source: 'lp_eo', sourceHandle: 'source-right', target: 'lp_fees', targetHandle: 'target-left', animated: false },
    { id: 'e-lp_ew-lp_fees', source: 'lp_ew', sourceHandle: 'source-right', target: 'lp_fees', targetHandle: 'target-left', animated: false },
    { id: 'e-lp_ewa-lp_fees', source: 'lp_ewa', sourceHandle: 'source-right', target: 'lp_fees', targetHandle: 'target-left', animated: false },
    { id: 'e-lp_es-lp_fees', source: 'lp_es', sourceHandle: 'source-right', target: 'lp_fees', targetHandle: 'target-left', animated: false },
    { id: 'e-lp_usdcspot-lp_fees', source: 'lp_usdcspot', sourceHandle: 'source-right', target: 'lp_fees', targetHandle: 'target-left', animated: false },
    { id: 'e-lp_fees-market_buy_eefi', source: 'lp_fees', sourceHandle: 'source-bottom', target: 'buy_eefi', animated: false },
    { id: 'e-market_buy_eefi-furnace', source: 'buy_eefi', sourceHandle: 'source-bottom', target: 'furnace', animated: false },

    { id: 'e-vault-rebase', source: 'vault', sourceHandle: 'source-bottom', target: 'rebase', animated: false },
    { id: 'e-rebase-r_pos', source: 'rebase', sourceHandle: 'source-bottom', target: 'r_pos', animated: false },
    { id: 'e-rebase-r_equ', source: 'rebase', sourceHandle: 'source-bottom', target: 'r_equ', animated: false },
    { id: 'e-rebase-r_neg', source: 'rebase', sourceHandle: 'source-bottom', target: 'r_neg', animated: false },
    { id: 'e-rebase-mint_eefi', label: '0.025', source: 'rebase', sourceHandle: 'source-bottom', target: 'mint_eefi', targetHandle: 'target-top', animated: false },

    { id: 'e-r_pos-pie_r_pos', source: 'r_pos', sourceHandle: 'source-bottom', target: 'pie_r_pos', animated: false },
    { id: 'e-r_equ-mint_eefi', label: 'x .0001', source: 'r_equ', sourceHandle: 'source-bottom', target: 'mint_eefi', animated: false },
    { id: 'e-r_neg-mint_eefi', label: 'x .00001', source: 'r_neg', sourceHandle: 'source-bottom', target: 'mint_eefi', animated: false },

    { id: 'e-mint_eefi-rewards_pool', source: 'mint_eefi', sourceHandle: 'source-bottom', target: 'rewards_pool', animated: false },

    { id: 'e-r_pos-0', label: '45%', source: 'pie_r_pos', sourceHandle: 'slice-1', target: 'buy_eefi' },
    { id: 'e-r_pos-1', label: '22%', source: 'pie_r_pos', sourceHandle: 'slice-0', target: 'buy_ohm' },
    { id: 'e-r_pos-2', label: '30%', source: 'pie_r_pos', sourceHandle: 'slice-2', target: 'ampl_reserved', targetHandle: 'target-bottom' },
    { id: 'e-r_pos-3', label: '3%', source: 'pie_r_pos', sourceHandle: 'slice-3', target: 'vault_ampl_fee' },

    { id: 'e-buy_ohm-treasury', source: 'buy_ohm', sourceHandle: 'source-bottom', target: 'treasury', animated: false },
    { id: 'e-ampl_fee-treasury', source: 'vault_ampl_fee', sourceHandle: 'source-bottom', target: 'treasury', animated: false },
    { id: 'e-ampl_reserved-vault', source: 'ampl_reserved', target: 'vault', targetHandle: 'target-right', animated: false },

    { id: 'e-vault_depositors-vault', source: 'vault_depositors', target: 'vault', targetHandle: 'target-left', animated: false },
    { id: 'e-rewards_pool-vault_depositors', source: 'rewards_pool', sourceHandle: 'source-left', target: 'vault_depositors', targetHandle: 'target-bottom', animated: false },

    { id: 'e-treasury-treasury_ops', source: 'treasury', sourceHandle: 'source-left', target: 'treasury_ops', animated: false },
    { id: 'e-treasury_ops-buy_eefi', source: 'treasury_ops', sourceHandle: 'source-left', target: 'buy_eefi', targetHandle: 'target-right', animated: false },

    { id: 'e-growth_assets-incoming_fees', source: 'growth_assets', sourceHandle:'source-bottom', target: 'incoming_fees', animated: false },
    { id: 'e-incoming_fees-retained_assets', label: 'retained assets', source: 'incoming_fees', sourceHandle: 'source-bottom', target: 'treasury', animated: false },
    { id: 'e-incoming_fees-buy_eefi', source: 'incoming_fees', sourceHandle: 'source-right', target: 'buy_eefi', targetHandle: 'target-right', animated: false },

    { id: 'e-bond_buyer-growth_assets', source: 'bond_buyer', sourceHandle: 'source-left', target: 'growth_assets', targetHandle: 'target-top', animated: false },
    { id: 'e-discounted_eefi-bond_buyer', markerEnd: {type:'arrowclosed'}, source: 'discounted_eefi', sourceHandle: 'source-top', target: 'bond_buyer', targetHandle: 'target-right', animated: false },
  ];

  