import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const BurnAreaChart = ({ data }) => (
  <ResponsiveContainer width="100%" height="100%">
    <AreaChart
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="timestamp"
        type="number"
        domain={['dataMin', 'dataMax']}
        scale="time"
        tickFormatter={(timestamp) => {
          const date = new Date(timestamp);
          return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        }}
      />
      <YAxis />
      <Tooltip
        labelFormatter={(label) => {
          const date = new Date(label);
          return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          });
        }}
        formatter={(value) => value.toFixed(2)}
        content={({ active, payload, label }) => {
          if (active && payload && payload.length) {
            const furnaceValue = payload.find(p => p.name === 'Furnace')?.value || 0;
            const vaultValue = payload.find(p => p.name === 'Vault')?.value || 0;
            const total = furnaceValue + vaultValue;
            
            return (
              <div className="bg-white p-2 border border-gray-200 shadow-sm">
                <p className="text-gray-600">
                  {new Date(label).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </p>
                {payload.map((entry) => (
                  <p key={entry.name} style={{ color: entry.color }}>
                    {`${entry.name}: ${entry.value.toFixed(2)}`}
                  </p>
                ))}
                <p className="text-gray-500 border-t border-gray-200 mt-1 pt-1">
                  {`Total: ${total.toFixed(2)}`}
                </p>
              </div>
            );
          }
          return null;
        }}
      />
      <Legend align="center" />
      <Area
        type="monotone"
        dataKey="furnaceCumulative"
        name="Furnace"
        stroke="#FF0000"
        fill="#FF0000"
        fillOpacity={0.3}
        stackId={1}
      />
      <Area
        type="monotone"
        dataKey="vaultCumulative"
        name="Vault"
        stroke="#FFA500"
        fill="#FFA500"
        fillOpacity={0.3}
        stackId={1}
      />
    </AreaChart>
  </ResponsiveContainer>
);

export default BurnAreaChart;