const rawAddressLabels = {
    '0x0bcfb6b59b76fcca52f4daa39223f97042460ccc' : 'Peapods: pEEFI (/DAI) Pod',
    '0x78f6e3c25ae5db269971dd29826e5f51e67fa018' : 'Peapods: pEEFI (/pOHM) Pod',
    '0xf950a86013baa227009771181a885e369e158da3' : 'Treasury',
    '0x5557f095556feb36c725f1ffe94a97631adeb770' : 'Elastic Vault',
    '0x5c60c284152fa40C5e558Dfc06da889433D86A28' : 'Treasury Trader',
    '0xcaf5b5D268032a41cAF34d9280A1857E3394Ba47' : 'Vesting Executor',
    '0xe9B230DA91FE0f451d9b92Fd5213Bde058567Cd1' : 'Furnace',
    '0x81734f304FF2Cf53F962415b4306e601Ffef9195' : 'LP Fee Collector',
    '0x44020977a3A521BBf4A9Bf126bd9a8FE6Af1194d' : 'Bond Manager',
    '0x007F7735baF391e207E3aA380bb53c4Bd9a5Fed6' : 'Bond Fixed-Term Teller',
    '0x007F7A1cb838A872515c8ebd16bE4b14Ef43a222' : 'Bond Market Creator',
    '0x79FE75708e834c5A6857A8B17eEaC651907c1dA8' : 'Uniswap v2: EEFI-OHM',
    '0x23655ec96b201Bf1574316783f3d943A955Ce5Fe' : 'Uniswap v3: EEFI-WETH',
    '0x6f40910629C53f3a64ca11d6191EC169893b6663' : 'Uniswap v3: EEFI-WAMPL',
    '0xb8C564177e54BF95Fb3D06242312Aaeb35446b5d' : 'Uniswap v3: EEFI-SPOT',
    '0x898aDC9aa0C23DCE3fED6456C34DbE2b57784325' : 'Uniswap v3: USDC-USDC',
    '0x3Bb4f0bC44714D0c9C5f18f7bCB63e3e0749b4bf' : 'Uniswap v2: pEEFI/DAI',
    '0xF8f54F573545A2e266DBB50C76687B19Aa589CDa' : 'Vault Rewards',
    '0x7661f376bec43C0de357d80658973bB84AF3be76' : 'Vault Rewards',
}

// Convert all keys to lowercase
export const addressLabels = Object.keys(rawAddressLabels).reduce((acc, key) => {
    acc[key.toLowerCase()] = rawAddressLabels[key];
    return acc;
}, {});