import React from 'react';
import { useQuery } from '@tanstack/react-query';
import BurnAreaChart from './BurnAreaChart';
import BurnLineChart from './BurnBarChart';
import MetricsUsingDT from './BurnMetrics';
import BurnMetrics from './BurnMetrics';

const API_URL = process.env.REACT_APP_API_URL;

// Fetch and transform data function remains the same
const fetchBurnData = async () => {
  const response = await fetch(`${API_URL}/burn`);
  if (!response.ok) {
    throw new Error("Failed to fetch burn data");
  }
  const rawData = await response.json();
  
  // Transform and sort by timestamp
  const chartData = rawData.map(item => ({
    timestamp: new Date(item.timestamp).getTime(),
    value: item.value,
    source: item.type
  }));
  chartData.sort((a, b) => a.timestamp - b.timestamp);

  // Group by day and source
  const dailySumsBySource = {};
  chartData.forEach(d => {
    const date = new Date(d.timestamp);
    const dateKey = date.toLocaleDateString('en-CA');
    if (!dailySumsBySource[dateKey]) {
      dailySumsBySource[dateKey] = { furnace: 0, vault: 0 };
    }
    if (d.source === 'FURNACE') {
      dailySumsBySource[dateKey].furnace += d.value;
    } else if (d.source === 'VAULT') {
      dailySumsBySource[dateKey].vault += d.value;
    }
  });

  // Convert daily sums to array and sort by date
  const dailyArray = Object.keys(dailySumsBySource).map(dateStr => {
    const [year, month, day] = dateStr.split('-');
    const dateObj = new Date(year, month - 1, day);
    return {
      timestamp: dateObj.getTime(),
      furnaceValue: dailySumsBySource[dateStr].furnace,
      vaultValue: dailySumsBySource[dateStr].vault,
      totalValue: dailySumsBySource[dateStr].furnace + dailySumsBySource[dateStr].vault
    };
  });
  dailyArray.sort((a, b) => a.timestamp - b.timestamp);

  // Generate cumulative sums
  let furnaceCumulative = 0;
  let vaultCumulative = 0;
  let totalCumulative = 0;
  const areaDataResult = dailyArray.map(d => {
    furnaceCumulative += d.furnaceValue;
    vaultCumulative += d.vaultValue;
    totalCumulative = furnaceCumulative + vaultCumulative;
    return {
      timestamp: d.timestamp,
      furnaceCumulative,
      vaultCumulative,
      totalCumulative
    };
  });

  return { lineData: dailyArray, areaData: areaDataResult };
};

const BurnCharts = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['burnData'],
    queryFn: fetchBurnData,
    staleTime: 5 * 60 * 1000,
    refetchInterval: 20 * 60 * 1000,
  });

  if (isLoading) {
    return <div className="text-center text-gray-500 py-4">Loading burn data...</div>;
  }

  if (isError) {
    return <div className="text-center text-red-500 py-4">Error loading burn data: {error.message}</div>;
  }

  const { lineData, areaData } = data;

  return (
    <div className="w-full px-4 py-6 md:px-8">
      <div className="max-w-6xl mx-auto">

        {/* Burn Metrics */}
        <BurnMetrics />

        {/* Cumulative Burns Chart */}
        <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-sm p-2 pb-8 overflow-hidden">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4 text-center">
            Cumulative Burns
          </h3>
          <div className="w-full h-[400px]">
            <BurnAreaChart data={areaData} />
          </div>
        </div>

        {/* Daily Burns Chart */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 pb-8 overflow-hidden">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4 text-center">
            Burns by Source
          </h3>
          <div className="w-full h-[400px]">
            <BurnLineChart data={lineData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurnCharts;