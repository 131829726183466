import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { useQueryClient } from '@tanstack/react-query';
import Home from './components/Home';
import About from './components/About/About';
import PlausibleEmbed from './components/PlausibleEmbed';
import Navbar from './components/Navbar';
import FloatingNav from './components/Navigation/FloatingNav';
import ThemeSwitcher from './components/Navigation/ThemeSwitcher';
import Breadcrumb from "./components/Navigation/Breadcrumb";
import AddressSummary from './components/AddressSummary/AddressSummary';
import BurnsTable from "./components/Burns/BurnTable";
import MintsTable from "./components/Mints/MintsTable";
import HolderTable from "./components/Holders/HolderTable";
import PresaleBlock from "./components/Presale/PresaleBlock";
import BurnCharts from "./components/Burns/BurnCharts";
import MintyBurny from "./components/MintyBurny/MintyBurny";
import FloatChart from "./components/MintyBurny/FloatChart";
import OverallFlows from "./components/Flows/OverallFlows";
import BasicBurnFlow from "./components/Flows/BasicBurns";
import FlowsDagre from "./components/Flows/FlowsDagre";
import MermaidDiagram from "./components/Flows/Mermaid";
import FlowsElk from "./components/Flows/FlowsElk";

const App = () => {
  useEffect(() => {
    document.title = 'EEFI Tools';
  }, []);

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "light"
  );

  useEffect(() => {
    const root = document.documentElement;
    if (theme === "dark") {
      root.classList.add("dark");
      root.classList.remove("light");
    } else {
      root.classList.add("light");
      root.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    const prefetchData = async () => {
      const baseURL = process.env.REACT_APP_API_URL;
      try {
        await Promise.all([
          queryClient.prefetchQuery({
            queryKey: ['token'],
            queryFn: () => fetch(`${baseURL}/token`).then(res => res.json()),
          }),
          queryClient.prefetchQuery({
            queryKey: ['treasury'],
            queryFn: () => fetch(`${baseURL}/treasury`).then(res => res.json()),
          }),
          queryClient.prefetchQuery({
            queryKey: ['mint'],
            queryFn: () => fetch(`${baseURL}/mint`).then(res => res.json()),
          }),
          queryClient.prefetchQuery({
            queryKey: ['burn'],
            queryFn: () => fetch(`${baseURL}/burn`).then(res => res.json()),
          }),
        ]);
      } catch (error) {
        console.error("Error prefetching data:", error);
      }
    };
    prefetchData();
  }, [queryClient]);

  return (
    <>
      <Helmet defaultTitle="EEFI Tools">
        <meta name="description" content="EEFI Tools - Comprehensive EEFI Analytics" />
      </Helmet>
      <Router>
        <FloatingNav theme={theme} toggleTheme={toggleTheme} />
        {/* Full-height container */}
        <div className="min-h-screen flex flex-col">
          {/* Main content with top padding for navbar spacing */}
          <main className="flex-grow flex flex-col bg-gray dark:bg-gray-900 pt-2 sm:pt-8">
            <div className="w-full max-w-7xl mx-auto sm:px-1 sm:py-2 px-1 py-2">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/supersecretanalytics" element={<PlausibleEmbed />} />
                <Route path="/treasury" element={<AddressSummary address="0xf950a86013baa227009771181a885e369e158da3" />} />
                <Route path="/charts" element={<BurnCharts />} />
                <Route path="/supply" element={<MintyBurny />} />
                <Route path="/burns" element={<BurnsTable />} />
                <Route path="/mints" element={<MintsTable />} />
                <Route path="/holders" element={<HolderTable />} />
                <Route path="/presale" element={<PresaleBlock />} />
                <Route path="/flows" element={<OverallFlows />} />
                <Route path="/dagre" element={<FlowsDagre />} />
                <Route path="/mermaid" element={<MermaidDiagram />} />
                <Route path="/elk" element={<FlowsElk />} />
                <Route path="/float" element={<FloatChart />} />
                <Route path="/basicburn" element={<BasicBurnFlow />} />
              </Routes>
            </div>
          </main>
        </div>
      </Router>
    </>
  );
};

export default App;