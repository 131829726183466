import React from 'react';

const GroupNode = ({ data, children }) => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '250px',
        height: '200px',
        backgroundColor: '#fdf6b2',
        border: '2px dashed #faca15',
        borderRadius: '8px',
        padding: '10px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: '5px',
        }}
      >
        {data.label}
      </div>
      {children}
    </div>
  );
};

export default GroupNode;
