import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
//import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
//import App from './App';
import FancyApp from './FancyApp';
import reportWebVitals from './reportWebVitals';

// Import from React Query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Create a QueryClient instance
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <FancyApp />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
