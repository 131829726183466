import React, { useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  useReactFlow
} from 'reactflow';

import 'reactflow/dist/style.css';
import '../../index.css'; // Ensure Tailwind is configured

import GroupNode from './GroupNode';
import FlameNode from './FlameNode';
import PieChartNode from './PieChartNode';

import ELK from 'elkjs/lib/elk.bundled.js';
const elk = new ELK();

const nodeTypes = {
    groupNode: GroupNode,
    flameNode: FlameNode,
    pieChartNode: PieChartNode,
  };  

const defaultNodes = [
  {
    id: '1',
    data: { label: 'Elastic Vault' },
    position: { x: 50, y: 50 },
  },
  {
    id: '2',
    data: { label: 'Liquidity Positions' },
    position: { x: 100, y: 50 },
  },
  {
    id: '3',
    data: { label: 'Growth Assets' },
    position: { x: 150, y: 50 },
  },
  {
    id: '4',
    data: { label: 'Incoming Fees/Proceeds' },
    position: { x: 100, y: 100 },
  },
  {
    id: '5',
    data: { label: 'Market Buy EEFI' },
    position: { x: 100, y: 100 },
  },
  {
    id: '6',
    data: { label: 'Furnace Burns EEFI' },
    position: { x: 100, y: 150 },
  },
];

const defaultEdges = [
  { id: 'e1-4', source: '1', target: '4', animated: true },
  { id: 'e2-4', source: '2', target: '4', animated: true },
  { id: 'e3-4', source: '3', target: '4', animated: true },
  { id: 'e4-5', source: '4', target: '5', animated: true },
  { id: 'e5-6', source: '5', target: '6', animated: true },
];

const applyElkLayout = async (nodes, edges) => {
  const elkGraph = {
    id: 'root',
    children: nodes.map((node) => ({
      id: node.id,
      width: 150, // Provide node width
      height: 50, // Provide node height
    })),
    edges: edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };

  const layout = await elk.layout(elkGraph, { 
    layoutOptions: { 
      'elk.algorithm': 'layered' ,
      'elk.direction': 'DOWN',   // Set the layout direction to top-down
      'elk.layered.spacing.nodeNodeBetweenLayers': '50', // Vertical spacing
    } 
  });

  return {
    nodes: nodes.map((node) => {
      const layoutNode = layout.children.find((n) => n.id === node.id);
      return {
        ...node,
        position: { x: layoutNode.x, y: layoutNode.y },
      };
    }),
    edges,
  };
};

const BasicBurnFlow = () => {
  const [layoutedNodes, setLayoutedNodes] = useState(defaultNodes);
  const [layoutedEdges, setLayoutedEdges] = useState(defaultEdges);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  useEffect(() => {
    const layoutGraph = async () => {
      const { nodes, edges } = await applyElkLayout(defaultNodes, defaultEdges);
      setLayoutedNodes(nodes);
      setLayoutedEdges(edges);

      // Trigger fitView when ReactFlowInstance is available
      if (reactFlowInstance) {
        reactFlowInstance.fitView({ padding: 0.1 });
      }
      
    };

    layoutGraph();
  }, [reactFlowInstance]);

  return (
    <div className="w-full h-screen bg-white dark:bg-gray-800 p-4 rounded shadow">
      <ReactFlow 
        nodes={layoutedNodes} 
        edges={layoutedEdges} 
        nodeTypes={nodeTypes} 
        fitView
        onInit={setReactFlowInstance} // Get ReactFlowInstance
        >
        <Background variant="dots" gap={12} size={1} />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default BasicBurnFlow;